
import { defineComponent, computed } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/vue'
import { cart, gift } from 'ionicons/icons';

import store from '@/store/index';

export default defineComponent({
    name: 'Coupons',

    components: {
        IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonIcon, IonGrid, IonRow, IonCol
    },

    setup(){

      const en = computed(() => store.state.lang == 'en')

      const switchLang = (en: string, ar: string) => {
        if(store.state.lang == 'en')
        { return en } 
        else if(store.state.lang == 'ar')
        { return ar }
      }
      
        return{
            //vue
            store,

            //Main
            en,
            
            //functions
            switchLang,

            //icons
            cart,
            gift

        }
    }
})
