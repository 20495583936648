<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-back-button text=""  color="primary" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title style="color: white">{{en ? 'Checkout' : 'الطلب'}}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <!-- <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Checkout</ion-title>
        </ion-toolbar>
      </ion-header> -->

      <div v-if="Object.keys(store.state.user.cart).length > 0" v-auto-animate>
        <ion-grid v-for="(supplier,i) in store.state.user.cart" :key="i" style="margin: 10px; box-shadow: 0px 0px 5px #000; border-radius: 20px 0px 20px 0px;" class="ion-no-padding ion-margin" dir="rtl">
          <div style="border: 1px solid #190f21; background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); color: white; text-shadow: 0px 0px 5px #000; border-radius: 20px 0px 20px 0px; height: 52px; text-align: center; padding: 0px; display: flex; justify-content: start" :dir="en ? 'ltr' : 'rtl'">
            <div><img style="height: 50px; border-radius: 20px 0px 20px 0px;" :src="supplier.supplier.logo"/></div>
            <div style="padding: 10px">{{switchLang(supplier.supplier.name_en, supplier.supplier.name_ar)}}</div>
          </div>
          <ion-row style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap; border-bottom: 2px solid #d2d2d2;">
            <div style="width: 100%; text-align: center" :dir="en ? 'ltr' : 'rtl'">{{en ? 'Minimum Order: ' : 'الحد الادنى للطلب: '}} <span style="color: lightgreen">{{supplier.supplier.minimum}} {{ en ? 'EGP' : 'ج' }}</span></div>
          </ion-row>
          <ion-row>
            <ion-col size="12" v-for="cartItem in supplier.items" :key="cartItem.id">
              <div style="border-bottom: 2px solid #d2d2d2; position: relative; font-size: 16px">
                <ion-row style="padding:10px 10px 0px 10px">
                  <ion-col size="1">
                    <ion-icon @click="removeItem(supplier.supplier, cartItem.productVersion)" :icon="trashOutline" style="position: absolute; right: 10px; top: 10px; font-size: 20px; z-index: 9"></ion-icon>
                  </ion-col>
                    <ion-col size="2">
                      <div style="padding: 5px;  width:50px">
                      <img style="border-radius: 20px;" :src="cartItem.product.image">
                      </div>
                    </ion-col>
                    <ion-col size="5">
                      <ion-row>
                        <div style="text-align: center; color: #E60060; font-weight: 700">{{switchLang(cartItem.product.name_en, cartItem.product.name_ar)}}</div>
                      </ion-row>
                      <ion-row style="">
                        <div style="text-align: center; color: #575757; ">{{switchLang(cartItem.product.description_en, cartItem.product.description_ar)}}</div>
                      </ion-row>
                      <ion-row style="">
                        <div style="text-align: center; color: black; ">{{switchLang(cartItem.productVersion.price + ' EGP', `${cartItem.productVersion.price}ج`)}}</div>
                      </ion-row>

                    </ion-col>
                    <ion-col size="4">
                      <ion-row>
                        <ion-col><div style="border: 1px solid #E60060; border-radius: 20px; text-align: center;font-weight: 700; padding: 2px 7px; color: #000098">{{switchLang(cartItem.quantity * cartItem.productVersion.price + 'EGP', cartItem.quantity * cartItem.productVersion.price  + 'ج')}}</div></ion-col>
                      </ion-row>
                      <ion-row>
                        <quantity-counter :productVersion="cartItem.productVersion" :checkout="true"/>
                      </ion-row>
                    </ion-col>
                  </ion-row>
              </div>
            </ion-col>
          </ion-row>
          <!-- <ion-row :style="total != finalTotal ? {textDecoration: 'line-through', color: 'gray'} : {}" style="display: flex; justify-content: center; font-size: 20px"> -->
          <ion-row style="display: flex; justify-content: center; font-size: 20px">
              {{switchLang('Total: ' + total(supplier) + 'EGP', 'المجموع: ' + total(supplier) + 'ج')}}
          </ion-row>
          <!-- <ion-row v-if="total != finalTotal" style="display: flex; justify-content: center; font-size: 20px">
              <div style="border: 3px solid #E60060; border-radius: 20px; color: #000098; padding: 5px 7px">{{switchLang('Total after Discounts ' + finalTotal + 'KD', 'المجموع بعد الخصم: ' + finalTotal + ' دك')}}</div>
          </ion-row>
          <ion-row style="display: flex; justify-content: center; font-size: 40px; margin-top:20px; color: #E60060">
              {{switchLang('Apply Coupon', 'استخدم كوبون')}}
          </ion-row>
          <ion-row>
            <ion-col size="4" v-for="coupon in store.state.coupons" :key="coupon.id">
                <div @click="applyCoupon(coupon)" style="border: 2px solid; border-radius: 20px; height: 65px">
                    <img :style="appliedCoupon.id == coupon.id ? {opacity: '0.2'} : {}" style="border-radius: 20px; height: 100%; " :src="coupon.image"/>
                </div>
            </ion-col>
          </ion-row>
          <ion-row style="display: flex; justify-content: center; font-size: 40px; margin-top:20px; color: #E60060">
              {{switchLang('Redeem Points', 'استخدم الكوينز')}}
          </ion-row>
          <ion-row style="display: flex; justify-content: center; items-align: center; font-size: 24px; margin-top:20px">
            <ion-col size="6">
              <div style="padding: 15px; display: flex; justify-content: start">
                <ion-input style="color: #000098; border-radius: 50px; box-shadow: 0px 0px 5px #00000080; width: 100px; text-align: center" :style="[$store.state.lang == 'en' ? {textAlign: 'right'} : {textAlign: 'left'} ,coinsUsed > store.state.user.coins ? {color: 'red'} : {}]" v-model="coinsUsed"></ion-input>
              </div>
            </ion-col>
            <ion-col size="6">

              <div style="display: flex; align-items: center">
                <div style="display: flex; color: #f3cc00; font-size: 24px">{{store.state.user.coins - coinsUsed}}</div>
                <div>
                  <coin style="width: 75px; height: 75px" />
                </div>
              </div>
            </ion-col>
          </ion-row> -->

          <ion-row>
            <ion-col size="12">
              <div style="font-weight: 700; font-size: 24px; color: #872bc1">{{en ? 'Payment Methods' : 'وسيلة الدفع'}}: <span style="color: lightgreen">{{ en ? 'Cash on delivery' : 'الدفع عند الاستلام' }}</span></div>
            </ion-col>
            <!-- <ion-col size="12">
              <ion-item lines="none">
                <ion-checkbox slot="start" checked="true" disabled="true"></ion-checkbox>
                <ion-label>{{ en ? 'Cash on delivery' : 'الدفع عند الاستلام' }}</ion-label>
              </ion-item>
            </ion-col> -->
          </ion-row>

          <ion-row>
              <ion-col style="display: flex; justify-content: center">
                  <ion-button :disabled="supplier.supplier.minimum > total(supplier)" @click="pay(supplier)" style="color: lightgreen; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px">{{switchLang('Place Order', 'اطلب')}}</ion-button>
              </ion-col>
          </ion-row>
        </ion-grid>
      </div>
        <ion-grid v-else  style="height: 100%;">

              <div style="height: 100%; display: flex; align-items: center; justify-content: center; font-size: 32px">
                {{switchLang('Shopping Bag is Empty', 'سلة المشتريات فارغة')}}
              </div>

        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { IonInput, IonButtons, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonButton } from '@ionic/vue';
import { cart, trashOutline } from 'ionicons/icons';
import QuantityCounter from './Components/QuantityCounter.vue'
// import Coin from '@/components/elements/toys/CoinIcon.vue'

import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';

export default defineComponent({
  name: 'Checkout',
  components: { QuantityCounter, 
  IonButtons, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonButton
  },

  setup(){

    const segmentChanged = () => {
      console.log('segment changed')
    }

    const en = computed(() => store.state.lang == 'en')

    const switchLang = (en, ar) => {
      if(store.state.lang == 'en')
      { return en } 
      else if(store.state.lang == 'ar')
      { return ar }
    }

    const removeItem = (supplier, item) => {
      store.state.user.cart[supplier.id].items.splice(store.state.user.cart[supplier.id].items.indexOf(item),1)
      if(store.state.user.cart[supplier.id].items.length == 0){
        delete store.state.user.cart[supplier.id]
      }
      localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
    }

    const total = (supplier) =>  supplier.items.reduce((ac,item) => ac + parseInt(item.productVersion.price) * parseInt(item.quantity),0)

    const appliedCoupon = ref({}) 

    const applyCoupon = (coupon) => {
      if(coupon.id == appliedCoupon.value.id){
        appliedCoupon.value = { id: 0, code: '0', value: 0, type: '0', descriptionEn: '0', descriptionAr: '0', image: '0'}
      } else {
        appliedCoupon.value = coupon
      }
    }

    const coinsUsed = ref(1000)

    // const finalTotal = computed(() => {
    //   let finalTotal = total.value

    //   if(appliedCoupon.value.id !== 0){
    //     if(appliedCoupon.value.type == 'bogo'){
    //       store.state.user.cart.map((cartItem) => {
    //         // cartItem.quantity = cartItem.quantity * 2
    //         finalTotal = total.value  
    //       })
    //     }else if(appliedCoupon.value.type == 'discountPercentage'){
    //       finalTotal = total.value - (total.value * (appliedCoupon.value.value /100)) 
    //     }else if(appliedCoupon.value.type == 'discountAmount'){
    //       finalTotal = total.value - appliedCoupon.value.value 
    //     }else{
    //       finalTotal = total.value 
    //     }
    //   }

    //   return finalTotal - (coinsUsed.value / 1000)
    // })

    // const pay = () => {
    //   store.state.user.orders.push({id: store.state.user.cart.length + 1, total: finalTotal.value, date: Date(), items: store.state.user.cart})
    //   localStorage.setItem('orders', JSON.stringify(store.state.user.orders))
    //   localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
    //   router.push('/client/bank')
    // }

    const pay = (supplier) => {

      const formData = new FormData()

      formData.append('supplier_id', supplier.supplier.id)
      formData.append('products', JSON.stringify(supplier.items))

      axios({
          method: 'post',
          url:`${store.state.api}orders`,
          data: formData,
          headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'Content-Type': 'multipart/form-data'
          }
      }).then( () => {
          store.state.toastMessage = 'Order Placed'
          store.state.toastColor = 'success'
          store.dispatch('openToast')
          store.dispatch('getClientProductsVersions')
          store.dispatch('getClientOrders')
          
          delete store.state.user.cart[supplier.supplier.id]
          localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
          localStorage.setItem('user', JSON.stringify(store.state.user))

          // router.push('/client/home')

      }).catch((errors) => {
          store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
          store.state.toastColor = 'danger'
          store.dispatch('openToast')
      })

      }

    return {
      //vue
      store,

      //Main
      switchLang,
      en,

      //functions
      removeItem,
      applyCoupon,
      pay,

      //ref
      coinsUsed,

      //variables
      // finalTotal,
      total,
      appliedCoupon,

      segmentChanged,

      //ICONS
      cart,
      trashOutline,
    }
  }
})
</script>

<style scoped>

.btn:active {
    transform: scale(0.9)
}

</style>