<template>
    <ion-page>
      <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff00">
          <ion-buttons slot="start">
            <ion-back-button text="" v-if="!store.state.editProductVersionFormOpened" color="primary" defaultHref="/supplier/categories"></ion-back-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">Goodz</div></ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
  
          <ion-grid v-if="Object.keys(store.state.activeProduct).length > 0" style="margin-bottom: 20px" :dir="store.state.lang == 'ar' ? 'rtl' : 'ltr'">
            <ion-row>
              <ion-col>
                <div v-if="!store.state.editProductVersionFormOpened" style="margin: 5px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px 0px 20px 0px">
                  <div style="padding: 5px; display: flex; justify-content: center">
                    <img style="border-radius: 20px; height: 300px; object-fit: contain" :src="store.state.activeProduct.image">
                  </div>
                  <div style="text-align: center">
                    <div>{{switchLang(store.state.activeProduct.name_en, store.state.activeProduct.name_ar)}}</div>
                    <div>{{switchLang(store.state.activeProduct.description_en, store.state.activeProduct.description_ar)}}</div>
                    <div>{{store.state.activeProduct.price}} {{ en ? 'EGP' : 'ج' }}</div>
  
                  </div>
                </div>

                <div v-for="pv in store.state.activeProduct.productVersions.filter(p => p.supplier.id == store.state.user.id)" :key="pv.id" style="display: flex; justify-content: space-between; margin: 10px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px 0px 20px 0px; padding: 10px 30px">
                  <div style="width: 50px">
                    <ion-icon v-if="pv.ordersCount == 0" @click="deleteProductVersion(pv)" size="large" :icon="trash" color="danger"></ion-icon>
                  </div>
                  <div>{{ pv.price }}</div>
                  <ion-item style="width: 100px">
                    <ion-select v-model="pv.status" @ionChange="editProductVersion(pv)">
                        <ion-select-option value="active">{{en ? 'Active' : 'فعال'}}</ion-select-option>
                        <ion-select-option value="inActive">{{en ? 'Inactive' : 'خامل'}}</ion-select-option>
                    </ion-select>
                  </ion-item>
                </div>

                <div v-if="addItem" style="display: flex; justify-content: space-between; align-items: center; margin: 10px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px 0px 20px 0px; padding: 10px 30px">
                  <div style="width: 50px">
                    <ion-icon @click="createNewProductVersion()" size="large" :icon="arrowUpCircle" color="success"></ion-icon>
                  </div>
                  <ion-input v-model="newPv.price" style="border-radius: 10px; text-align: center; box-shadow: 0px 0px 10px #c0c0c0; max-width:90px; margin: 10px; height: 40px"></ion-input>
                  <ion-item style="width: 100px">
                    <ion-select v-model="newPv.status" >
                        <ion-select-option value="active">{{en ? 'Active' : 'فعال'}}</ion-select-option>
                        <ion-select-option value="inActive">{{en ? 'Inactive' : 'خامل'}}</ion-select-option>
                    </ion-select>
                  </ion-item>
                </div>

                <div v-if="!addItem" style="display: flex; justify-content: center; width: 100%; padding: 20px">
                  <ion-icon @click="addItem = true" color="success" :icon="addCircle" size="large"/>
                </div>
  
                <!-- <ProductVersionForm v-if="store.state.editProductVersionFormOpened" :entryType="{process: 'Edit'}"/> -->
  
              </ion-col>
            </ion-row>
          </ion-grid>
  
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { defineComponent, computed, ref, onMounted, reactive } from 'vue'
  import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonItem, IonLabel, IonSelect, IonSelectOption, IonInput } from '@ionic/vue';
  import { trash, create, addCircle, arrowUpCircle } from 'ionicons/icons';
  import { useRoute } from 'vue-router'
  import store from '@/store/index';
  import router from '@/router/index';
  // import ProductVersionForm from '@/views/Supplier/Pages/ProductVersionForm.vue'
  import axios from 'axios'
  
      onMounted(() => {
        // store.dispatch('getSuppliersProducts')
        // store.dispatch('getSupplierProductsVersions')
      })

      const en = computed(() => store.state.lang == 'en')
  
      const switchLang = (en, ar) => {
        if(store.state.lang == 'en')
        { return en } 
        else if(store.state.lang == 'ar')
        { return ar }
      }
  
      const route = useRoute()

      onMounted(() => {
        store.dispatch('getSupplierProductAndVersions', route.params.id)
      })
      
      const editProductVersion = (pv) => {
        const formData = new FormData()

        formData.append('status', pv.status)

        formData.append("_method", 'PATCH');
        
        axios({
            method: 'post',
            url:`${store.state.api}productVersions/${pv.id}`,
            data: formData,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'Content-Type': 'multipart/form-data'
            }
        }).then( () => {
            store.state.toastMessage = 'Product Version Updated!'
            store.state.toastColor = 'success'
            store.dispatch('openToast')
            store.dispatch('getSupplierProductAndVersions', route.params.id)
            // store.dispatch('getSupplierProductsVersions')

            // location.reload()
            
        }).catch((errors) => {
            store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })
      }
  
      const deleteProductVersion = (pv) => {
        axios({
            method: 'delete',
            url:`${store.state.api}productVersions/${pv.id}`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'Content-Type': 'multipart/form-data'
            }
        }).then( () => {
            store.state.toastMessage = 'Product Deleted!'
            store.state.toastColor = 'success'
            store.dispatch('openToast')
            store.dispatch('getSupplierProductAndVersions', route.params.id)
    
            // location.reload()
            
        }).catch((errors) => {
            store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })
      }

      const addItem =ref(false)

      const newPv = reactive({
        price: '',
        status: 'inActive',
      })

      const createNewProductVersion = () => {

        if(newPv.price != '' && newPv.price != null){

            const formData = new FormData()

            Object.keys(newPv).map(e => {
                formData.append(e, newPv[e])
            })

            formData.append('product_id', store.state.activeProduct.id)
            formData.append('user_id', store.state.user.id)
            
            axios({
                method: 'post',
                url:`${store.state.api}productVersions`,
                data: formData,
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'multipart/form-data'
                }
            }).then( () => {
                store.state.toastMessage = 'Product Version Created'
                store.state.toastColor = 'success'
                store.dispatch('openToast')
                store.dispatch('getSupplierProductAndVersions', route.params.id)

                addItem.value = false

            }).catch((errors) => {
                store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
                store.state.toastColor = 'danger'
                store.dispatch('openToast')
            })
            
        } else {
            store.dispatch('missingData')
        }

        }
  
  </script>
  
  <style scoped>
  
  </style>