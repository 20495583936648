import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","text-align":"center","font-size":"32px","font-weight":"700","color":"#E60060"} }
const _hoisted_2 = {
  key: 0,
  style: {"position":"absolute","top":"0px","right":"30px","padding":"0px 5px","z-index":"9","background":"lightgreen","height":"20px","border-radius":"50px","text-align":"center","font-size":"16px"}
}
const _hoisted_3 = {
  key: 1,
  style: {"position":"absolute","top":"18px","right":"30px","padding":"0px 7px 0px 7px","z-index":"9","background":"lightblue","height":"22px","border-radius":"50px","text-align":"center","font-size":"16px"}
}
const _hoisted_4 = ["dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        translucent: true,
        style: {"background-image":"linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { style: {"--background":"#ffffff00"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "primary" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, {
                size: "large",
                style: {"text-align":"center","margin-top":"10px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.en ? 'Contact Us' : 'تواصلوا معنا'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, {
                slot: "end",
                style: {"position":"relative"}
              }, {
                default: _withCtx(() => [
                  (Object.keys(_ctx.store.state.user.cart).length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.store.getters.getCartItemsCount), 1))
                    : _createCommentVNode("", true),
                  (Object.keys(_ctx.store.state.user.cart).length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("span", {
                          style: {"color":"white"},
                          dir: _ctx.en ? 'ltr' : 'rtl'
                        }, _toDisplayString(_ctx.en ? 'EGP' : 'ج'), 9, _hoisted_4),
                        _createTextVNode(_toDisplayString(_ctx.store.getters.getCartTotal), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_icon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/checkout'))),
                    size: "large",
                    icon: _ctx.cart,
                    color: "primary"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { style: {"display":"flex","justify-content":"center","width":"100%","font-size":"32px","margin-top":"30px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.switchLang('Leave us a message', 'اتركوا لنا رسالة')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, {
                    dir: _ctx.store.state.lang == 'ar' ? 'rtl' : 'ltr',
                    style: {"display":"flex","justify-content":"center"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.switchLang('Message', 'الرسالة')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_textarea)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.switchLang('Phone Number', 'رقم التليفون')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["dir"]),
                  _createVNode(_component_ion_row, { style: {"width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { style: {"display":"flex","justify-content":"center","width":"100%"} }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.contactUs && _ctx.contactUs(...args))),
                            style: {"background":"lightgreen","padding":"10px","margin":"10px","border-radius":"20px","width":"200px","text-align":"center","font-size":"20px"}
                          }, _toDisplayString(_ctx.switchLang('Contact us', 'تواصلوا معنا')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { style: {"display":"flex","justify-content":"center","width":"100%","font-size":"32px","margin-top":"30px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.switchLang('Contact Us Directly', 'تواصلوا معنا مباشرة')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { style: {"display":"flex","justify-content":"center","width":"100%","font-size":"20px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.switchLang('Working hours from 11am to 10pm', 'اوقات العمل من ١١ صباحا الى ١٠ مسائا')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "3",
                    style: {"padding":"15px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.track({type: 'ad', type_action: 'call', type_id: _ctx.ad.id}))),
                        href: 'tel: 69988524'
                      }, [
                        _createVNode(_component_ion_icon, {
                          style: {"color":"black"},
                          size: "large",
                          icon: _ctx.call
                        }, null, 8, ["icon"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { size: "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.track({type: 'ad', type_action: 'whatsapp', type_id: _ctx.ad.id}))),
                        color: "black",
                        href: 'https://api.whatsapp.com/send?phone=96560407111'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_img, {
                            style: {"width":"30px"},
                            src: "@/../assets/images/whatsapp-200.png"
                          })
                        ]),
                        _: 1
                      }, 8, ["href"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}