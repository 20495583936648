<template>
    <ion-row class="card" @click="showOrders = !showOrders">
        <ion-col  v-auto-animate>
        <ion-row style="padding: 20px" :dir="en ? 'ltr' : 'rtl'">
            <ion-col size="8" style="position: relative">
                <div>
                <div class="card-header" style="" :dir="en ? 'ltr' : 'rtl'">
                    <div>{{ `${en ? user.name_en : user.name_ar}` }} </div>
                    <!-- <div>{{ `ID: ${user.id}` }} </div> -->
                </div>
                <div class="card-body" :dir="en ? 'ltr' : 'rtl'">
                    <!-- <div><span>{{en ? 'Status' : 'الحالة'}}: </span><span>{{ user.status }}</span></div> -->
                    <div><span>{{en ? 'Email' : 'الايميل'}}:</span><span>{{ user.email }}</span></div>
                    <div><span>{{en ? 'Phone Number' : 'رقم التليفون'}}:</span><span>{{ user.phone_number }}</span></div>
                </div>
                </div>
            </ion-col>
            <ion-col size="4">
                <div v-if="user.logo">
                    <img :src="user.logo"/>
                </div>
            </ion-col>
        </ion-row>

        <ion-row v-if="showOrders">

        <ion-col size="12" v-for="order in store.state.orders.filter(o => o.client.id == user.id)" :key="order.id">
            <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px; height: 100%">
                <div>
                    <ion-row>
                    <ion-col size="6" style="color: #ddb901; font-weight: 700">{{order.created_at.slice(0,10)}} - {{order.created_at.slice(11,16)}}</ion-col>
                    <ion-col size="1"></ion-col>
                    <ion-col size="5" style="color: #000098;display: flex; align-items: center; justify-content: end; font-weight: 700">{{order.product_versions.reduce((acc,pv)=>acc+(pv.price*pv.pivot.quantity),0)}} {{ en ? 'EGP' : 'ج' }}</ion-col>
                    </ion-row>
                    <ion-row v-for="item in order.product_versions" :key="item.id" style="border-top: 1px solid #000098">
                        <ion-col size="2"><div><img style="width: 50px" :src="item.product.image"/></div></ion-col>
                        <ion-col size="5" style="display: flex; align-items: center; color: #000098"><div>{{en ? item.product.name_en : item.product.name_ar}}</div></ion-col>
                        <ion-col size="5" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div><span style="color: black; font-size: 14px">{{item.price}} X {{ item.pivot.quantity }}</span> <br> {{item.price * item.pivot.quantity }} {{ en ? 'EGP' : 'ج' }}</div></ion-col>
                    </ion-row>
                </div>
                </div>
            </ion-col>
        </ion-row>
    </ion-col>
    </ion-row>
</template>

<script>
import { defineComponent, onMounted, computed, ref } from 'vue'
import {IonRow, IonCol } from '@ionic/vue'
import store from '@/store/index';

    export default defineComponent({
        name: 'admin user card',

        props: ['user'],
    
        components: { 
            IonRow, IonCol
        },

        setup(props){
            const en = computed(() => store.state.lang == 'en')

            const showOrders = ref(false)
            return{
                //Vue
                store,

                //Main
                en,

                //Data
                showOrders,

                //Functions

                //Icons
            }
        }
    })
</script>

<style scoped>
.card{
    border-radius: 20px; 
    box-shadow: 0px 0px 5px #00000080; 
    margin: 5px;
    /* font-weight: 700; */
}

.card-header{
    color: #e60060;
    font-size: 32px;
}

.card-body{
    color: #91148d;
}
</style>