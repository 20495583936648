<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060" v-if="route.meta.type == 'Suppliers'">{{ en ? route.meta.type : 'الموردين' }}</div></ion-title>
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060" v-if="route.meta.type == 'Clients'">{{ en ? route.meta.type : 'العملاء' }}</div></ion-title>
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060" v-if="route.meta.type == 'Users'">{{ en ? 'Admins' : 'ادمنز' }}</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <!-- <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 20px; right: 30px; padding: 0px 5px; z-index: 9; background: lightblue; height: 20px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white">EGP</span>{{ store.getters.getCartTotal }}</div> -->
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
      
    <ion-content :fullscreen="true">
      
      <div v-if="!store.state.editUserFormOpened">
        <ion-grid style="margin-bottom: 20px;">
          <UserCard @click="goToUser(u)" v-for="u in users" :key="u.id" :user="u"/>
        </ion-grid>
      </div>

      <div v-if="store.state.editUserFormOpened">
        <UserForm v-if="store.state.editUserFormOpened" :entryType="{process: 'New'}" roleId="2"/>
      </div>

      <div v-if="!store.state.editUserFormOpened" style="position: fixed; bottom: 0px; right: 20px">
        <div @click="store.state.editUserFormOpened = true" style="color: lightgreen; font-size: 72px;"><ion-icon :icon="addCircle"></ion-icon></div>
      </div>

    </ion-content>
  </ion-page>
</template>
    
    <script>
    import { defineComponent, onMounted, computed } from 'vue'
    import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonIcon } from '@ionic/vue'
    import { addCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router'

    import UserCard from './Components/UserCard.vue'
    import UserForm from '@/views/Admin/Pages/UserForm.vue'

    import store from '@/store/index';
    import router from '@/router/index';

    export default defineComponent({
        name: 'admin suppliers',
    
        components: { UserCard, UserForm,
            IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonIcon
        },
    
        setup(){
    
          const switchLang = (en, ar) => {
            if(store.state.lang == 'en')
            { return en } 
            else if(store.state.lang == 'ar')
            { return ar }
          }

          const en = computed(() => store.state.lang == 'en')

          const route = useRoute();

          onMounted(() => {
            if(route.meta.type == 'Suppliers'){
              store.dispatch('getAdminSuppliers')
            } else if(route.meta.type == 'Clients') {
              store.dispatch('getAdminClients')
            } else if(route.meta.type == 'Users') {
              store.dispatch('getAdminUsers')
            }
          })

          const users = computed(() => {
            if(route.meta.type == 'Suppliers'){
              return store.state.suppliers
            } else if(route.meta.type == 'Clients') {
              return store.state.clients
            }else if(route.meta.type == 'Users') {
              return store.state.adminUsers
            } else {
              return []
            }
          })

          const goToUser = (user) => {
            store.state.activeUser = user
            router.push('/admin/' + route.meta.type.toLowerCase() + '/' + user.id)
          }
          
          return{
            //vue
            store,
            route,

            //Main
            en,

            //Data
            users,

            //functions
            switchLang,
            goToUser,

            //icons
            addCircle,
    
          }
        }
    })
    </script>