<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Orders' : 'الطلبات' }}</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <ion-icon @click="store.dispatch('getSupplierOrders')" size="large" :icon="sync" color="primary"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid>
          <ion-row>
            <OrderCard size="12" v-for="order in store.state.orders" :key="order.id" :order="order"/>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonIcon } from '@ionic/vue';
import { sync, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios'
import OrderCard from './Components/OrderCard.vue'

export default defineComponent({
  name: 'Orders',
  components: { OrderCard,
     IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonIcon
  },

  setup(){

    const en = computed(() => store.state.lang == 'en')

    onMounted(() => {
      store.dispatch('getSupplierOrders')
    })

    return {
      //Vue
      store,
      router,

      //Main
      en,

      //Functions

      //ICONS
      sync,
      heart, 
      heartOutline,
    }
  }
})
</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>