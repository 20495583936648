<template>
    <div :dir=" en ? 'ltr' : 'rtl'" v-if="Object.keys(store.state.productToEdit).length > 0 || entryType.process == 'New'">

        <ion-row style="padding: 20px 10px">
            <ion-col size="6" style="text-align: center;" v-if="entryType['process'] == 'Edit' && store.state.productToEdit != null">
                <div style="font-weight: 700; color: #969696" v-if="image">{{ en ? 'Current' : 'الحالي' }}</div>
                <ion-img :src="store.state.productToEditImage"></ion-img>
            </ion-col>
            <ion-col size="6" style="text-align: center; display: flex; justify-content: center; align-items: center; border: 1px dotted #969696; border-radius: 20px; flex-wrap: wrap;">
                <div style="font-weight: 700; color: #969696" v-if="image">{{ en ? 'New' : 'الجديد' }}</div>
                <ion-img style="width: 100%" v-if="image" :src="image"></ion-img>
                <button style="background-color: #642774; padding: 10px; border-radius: 50px" id="fileInputButton" onclick="document.getElementById('fileInput').click()">{{ entryType['process'] == 'Edit' ? en ? 'Choose New image' : 'اختر صورة جديدة' : en ? 'Choose image' : 'اختر صورة' }}</button>
            </ion-col>
        </ion-row>
        <!-- Hidden image input -->
        <input style="display:none" id="fileInput" type="file" @change="imageFile" accept="image/png, image/jpeg">
        <!-- Hidden image input -->

        <ion-item>
            <ion-label position="floating">{{ en ? 'English Name' : 'الاسم الانجليزي' }}</ion-label>
            <ion-input v-model="store.state.productToEdit.name_en"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Arabic Name' : 'الاسم العربي' }}</ion-label>
            <ion-input v-model="store.state.productToEdit.name_ar"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'English Description' : 'الوصف الانجليزي' }}</ion-label>
            <ion-input v-model="store.state.productToEdit.description_en"></ion-input>
        </ion-item>
        
        <ion-item>
            <ion-label position="floating">{{ en ? 'Arabic Description' : 'الوصف العربي' }}</ion-label>
            <ion-input v-model="store.state.productToEdit.description_ar"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Category' : 'الفئة' }}</ion-label>
            <ion-select v-model="cats.category">
                <ion-select-option v-for="p in store.state.categories" :key="p.id" :value="p.id">{{en ? p.name_en : p.name_ar}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item v-if="cats.category">
            <ion-label position="floating">{{ en ? 'Product' : 'الفئة الخاصة' }}</ion-label>
            <ion-select v-model="store.state.productToEdit.subcategory_id"  :value="store.state.productToEdit.subcategory_id">
                <ion-select-option v-for="p in store.state.categories.find(cat=> cat.id == cats.category).subcategories" :key="p.id" :value="p.id">{{en ? p.name_en : p.name_ar}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Status' : 'الحالة' }}</ion-label>
            <ion-select v-model="store.state.productToEdit.status">
                <ion-select-option value="active">{{en ? 'Active' : 'فعال'}}</ion-select-option>
                <ion-select-option value="inActive">{{en ? 'Inactive' : 'خامل'}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-row class="ion-justify-content-center ion-padding">
            <div class="actionButton" @click="submitForm()" >{{ en ? entryType['process'] : entryType['process'] == 'Edit' ? 'تحديث' : 'اضافة' }}</div>
            <div class="cancelButton" @click="handleDidDismiss()">{{ en ? 'Cancel' : 'الغاء' }}</div>
        </ion-row>

    </div>
</template>

<script>
import { defineComponent, reactive, onUpdated, computed, ref, onMounted} from 'vue';
import { IonRow, IonInput, IonItem, IonCol, IonImg, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import store from '@/store/index.ts';
import { useRoute } from 'vue-router'
import axios from 'axios'
import { chevronBackCircle } from 'ionicons/icons';

export default defineComponent({

    name: 'ProductForm',

    components: { IonRow, IonInput, IonItem, IonCol, IonImg, IonLabel, IonSelect, IonSelectOption },
    
    props: [
        'isOpen',
        'entryType',
        'edit',
    ],

    setup(props){

        const en = computed(() => store.state.lang == 'en')

        const form = reactive ({
            image: '',
        });
        

        const image = ref()

        const imageFile = (img) => {

            store.state.productToEdit.image = img.target.files[0]
            
            const file = img.target.files[0]
            image.value = URL.createObjectURL(file)

            // // store.state.products.find(p => p.id == parseInt(route.params.id, 10)).image = image.url
        }


        const handleDidDismiss = () => {
            store.state.editProductFormOpened = false
            store.state.productToEdit.name_en = ''
            store.state.productToEdit.name_ar = ''
            store.state.productToEdit.description_en = ''
            store.state.productToEdit.description_ar = ''
            store.state.productToEdit.image = ''
            image.value = null

            store.state.productToEdit = {}
        }


        const validated = () => {
            return store.state.productToEdit.name_en != null && store.state.productToEdit.name_en != '' &&
            store.state.productToEdit.name_ar != null && store.state.productToEdit.name_ar != '' &&
            store.state.productToEdit.description_en != null && store.state.productToEdit.description_en != '' &&
            store.state.productToEdit.description_ar != null && store.state.productToEdit.description_ar != '' &&
            store.state.productToEdit.subcategory_id != null && store.state.productToEdit.subcategory_id != ''
        }

        const createItem = () => {

            if(validated()){

                const formData = new FormData()

                Object.keys(store.state.productToEdit).map(e => {
                    formData.append(e, store.state.productToEdit[e])
                })
                
                axios({
                    method: 'post',
                    url:`${store.state.api}products`,
                    data: formData,
                    headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'Content-Type': 'multipart/form-data'
                    }
                }).then( () => {
                    store.state.toastMessage = 'Product Created'
                    store.state.toastColor = 'success'
                    store.dispatch('openToast')
                    store.dispatch('getAdminProducts', {page: 1, subcategory_id: route.params.id})

                    store.state.editProductFormOpened = false

                }).catch((errors) => {
                    console.log(errors.message)
                    store.state.toastMessage = errors.response.status == 413 ? en.value ? 'The image is too large' : 'حجم الصورة اكبر من المسموح به' : errors.message
                    store.state.toastColor = 'danger'
                    store.dispatch('openToast')
                })
                 
            } else {
                store.dispatch('missingData')
            }

        }

        const route = useRoute()

        const updateItem = () => {

           if(validated()){

                const formData = new FormData()

                Object.keys(store.state.productToEdit).map(e => {
                    formData.append(e, store.state.productToEdit[e])
                })
                formData.append("_method", 'PATCH');
                formData.delete('category_id')
                formData.delete('productVersions')
                
                axios({
                    method: 'post',
                    url:`${store.state.api}products/${store.state.productToEdit.id}`,
                    data: formData,
                    headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'Content-Type': 'multipart/form-data'
                    }
                }).then( () => {
                    store.state.editProductFormOpened = false
                    store.state.toastMessage = 'Product Updated!'
                    store.state.toastColor = 'success'
                    store.dispatch('openToast')
                    store.dispatch('getSupplierProductAndVersions', route.params.id)

                    // location.reload()
                    
                }).catch((errors) => {
                    store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
                    store.state.toastColor = 'danger'
                    store.dispatch('openToast')
                })

            } else {
                store.dispatch('missingData')
            }
        }

        const submitForm = () => {
            if (props.entryType['process'] == 'New') {
                createItem()
            }

            else if (props.entryType['process'] == 'Edit') {
                updateItem()
            }
        }

        onUpdated(() => {

            if (store.state.productToEdit && props.entryType['process'] == 'Edit') {

                form.nameEn = store.state.productToEdit.nameEn      
                form.nameAr = store.state.productToEdit.nameAr      
                form.image = store.state.productToEdit.image
                form.descEn = store.state.productToEdit.descEn
                form.descAr = store.state.productToEdit.descAr
                form.phone = store.state.productToEdit.phone
                form.email = store.state.productToEdit.email
            
            }
        })

        const cats = reactive({
            category:'',
            subcategory:'',
        })

        onMounted(()=>{
            cats.category = store.state.productToEdit.category_id
        })

        return {
            //Main
            store,
            en,

            //Methods
            handleDidDismiss,
            submitForm,
            image,
            imageFile,

            //Properties
            form,
            cats,

            //Icons
            chevronBackCircle,


        }
    }
})
</script>

<style scoped>

    ion-content{
    width: calc(100% + 15px)
    }
    ion-content::part(scroll){
    padding-right: 15px
    }

    .clickable {
        pointer-events: auto !important;
    }

    .actionButton {
        margin: 10px;
        padding: 10px;
        border: 1px solid greenyellow;
        border-radius: 100px;
        width: 100px;
        text-align: center;
    }

    .cancelButton {
        margin: 10px;
        padding: 10px;
        border: 1px solid red;
        border-radius: 100px;
        width: 100px;
        text-align: center;
    }
    .bigH {
        margin-top:0px; 
        text-align: center; 
        font-size: 40px;  
        text-shadow: 1px 1px 5px #000000;
    }

    .label-floating.sc-ion-label-ios-h{
    --color: #642774;
    font-weight: 700
    }
</style>