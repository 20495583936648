
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import Coin from '@/components/elements/toys/CoinIcon.vue'

export default defineComponent({
  name: 'Wishlist',
  components: { Coin, 
     IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon
  },

  setup(){

    const slider = ref()

    const en = computed(() => store.state.lang == 'en')

    const slideNext = async () => { await slider.value.$el.slideNext() }
    setInterval(() => {
      if(slider.value){
        slideNext()
      }
    }, 3000)

    interface Product {
        id: number;
        en: object;
        ar: object;
        type: string;
        image: string;
        price: number;
        categories: Array<string>;
    }

    onMounted(()=>{
      if(localStorage.getItem("wishlist")){
        store.state.user.wishlist = JSON.parse(localStorage.getItem("wishlist") as string)
      }
    })

    const mainCategory = ref('boys')
    const subcategory = ref('cars')

    const products = computed(() => {
      return store.state.user.wishlist
    })

    const segmentChanged = (ev: CustomEvent) => {
      mainCategory.value = ev.detail.value
    }

    const switchLang = (en: string, ar: string) => {
      if(store.state.lang == 'en')
      { return en } 
      else if(store.state.lang == 'ar')
      { return ar }
    }

    const goToProduct = (product: Product) => {
      store.state.activeProduct = product
      router.push('/client/productsVersions/' + product.id)
    }

    const slideOpts = {
      initialSlide: 1,
      speed: 1000,
      loop: true
    };


    const addToWishlist = (product: Product) => {
      if(store.state.user.wishlist.includes(product)){
        store.state.user.wishlist.splice(store.state.user.wishlist.indexOf(product),1)
        localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
      } else {
        store.state.user.wishlist.push(product)
        localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
      }
    }

    return {
      //Vue
      store,
      router,
      
      //Main
      en,
      switchLang,

      //Data
      products,

      //functions
      addToWishlist,

      segmentChanged,
      goToProduct,
      slideOpts,
      slider,

      //ICONS
      cart,
      heart, 
      heartOutline,
    }
  }
})
