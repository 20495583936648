<template>
    <ais-instant-search :search-client="searchClient" index-name="products">

        <ais-configure>
        <ais-autocomplete>
            <template v-slot="{ currentRefinement, indices, refine }">
            <div style="position: relative; text-align: center">
                <div style="padding: 10px; position: relative">
                    <input class="neo-morph-borders" type="search" v-model="searchTerm" :placeholder="en ? 'Search' : 'بحث'" @input="refine($event.currentTarget.value)" />
                    <div @click="clear(refine(''))" v-if="currentRefinement" style="position: absolute; right:22px; top: 22px"><ion-icon size="large" :icon="closeCircle"/></div>
                </div>
                <div v-show="currentRefinement" style="position: absolute; z-index: 10; width: 100%; padding:0px 10px">
                    <div>
                        <div style="display: flex; justify-content: space-around; align-items: center; background: white; box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff; border-radius: 20px; margin: 10px; padding: 0px 20px"  v-for="hit in indices[0].hits" :key="hit.objectID" >
                            <ais-highlight style="width: 180px" v-if="en" attribute="name_en" :hit="hit" @click.self="router.push(`${supplier ? '/supplier/' : admin ? '/admin/' : '/client/'}productsVersions/${hit.id}`)"/>
                            <ais-highlight style="width: 180px" dir="rtl" v-if="!en" attribute="name_ar" :hit="hit" @click.self="router.push(`${supplier ? '/supplier/' : admin ? '/admin/' :  '/client/'}productsVersions/${hit.id}`)"/>
                            <!-- <div v-if="supplier" style="display: flex; justify-content: space-around; align-items: center; ">
                                <ion-input type="number" style="border-radius: 10px; text-align: center; box-shadow: 0px 0px 10px #c0c0c0; max-width:90px; margin: 10px; height: 40px"></ion-input>
                                <div style="width: 50px">Add</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            </template>
        </ais-autocomplete>
        </ais-configure>

    </ais-instant-search>

</template>

<script setup>
import { defineProps, ref, computed, onMounted } from 'vue'
import { IonInput, IonIcon } from '@ionic/vue';
import { closeCircle } from 'ionicons/icons';

import store from '@/store/index';
import router from '@/router/index';

import algoliasearch from 'algoliasearch/lite';
import { AisInstantSearch, AisAutocomplete, AisHighlight, AisConfigure } from 'vue-instantsearch/vue3/es';
import 'instantsearch.css/themes/satellite-min.css';

    const en = computed(() => store.state.lang == 'en')

    const props = defineProps({
        supplier: {
            type: Boolean,
            default: false
        },
        admin: {
            type: Boolean,
            default: false
        }
    })

    const searchTerm = ref('')

    const searchClient = algoliasearch(
        'Q3HTJ1IRSA',
        '8bd82d03281afd7cc4956696a0e1ffe1'
    )

    const clear = (refine) => {
        refine
        searchTerm.value = ''
    }

</script>

<style scoped>

.neo-morph-borders{
    -webkit-appearance: none;
    width: 100%;
    text-align: center;
    padding:5px;
    background: white;;
    border: 5px solid white; border-radius: 50px; 
    box-shadow: inset 20px 20px 60px #d7d7d7, 
    inset -20px -20px 60px #e9e9e9,
    20px 20px 60px #bebebe, 
    -20px -20px 60px #ffffff;
}

.neo-morph-borders:focus{
    outline: none;
}



</style>