import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","text-align":"center","font-size":"32px","font-weight":"700","color":"#E60060"} }
const _hoisted_2 = {
  key: 0,
  style: {"position":"absolute","top":"0px","right":"30px","padding":"0px 5px","z-index":"9","background":"lightgreen","height":"20px","border-radius":"50px","text-align":"center","font-size":"16px"}
}
const _hoisted_3 = {
  key: 1,
  style: {"position":"absolute","top":"18px","right":"30px","padding":"0px 7px 0px 7px","z-index":"9","background":"lightblue","height":"22px","border-radius":"50px","text-align":"center","font-size":"16px"}
}
const _hoisted_4 = ["dir"]
const _hoisted_5 = { style: {"border-radius":"20px","box-shadow":"0px 0px 5px #00000080","padding-bottom":"1px"} }
const _hoisted_6 = ["src"]
const _hoisted_7 = { style: {"text-align":"center","color":"#E60060"} }
const _hoisted_8 = { style: {"text-align":"center","color":"white","padding":"5px","margin":"5px","background":"#E60060","font-weight":"700","border":"3px solid #000098","border-radius":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        translucent: true,
        style: {"background-image":"linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { style: {"--background":"#ffffff00"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "primary" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, {
                size: "large",
                style: {"text-align":"center","margin-top":"10px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.en ? 'Coupons' : 'الكوبونات'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, {
                slot: "end",
                style: {"position":"relative"}
              }, {
                default: _withCtx(() => [
                  (Object.keys(_ctx.store.state.user.cart).length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.store.getters.getCartItemsCount), 1))
                    : _createCommentVNode("", true),
                  (Object.keys(_ctx.store.state.user.cart).length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("span", {
                          style: {"color":"white"},
                          dir: _ctx.en ? 'ltr' : 'rtl'
                        }, _toDisplayString(_ctx.en ? 'EGP' : 'ج'), 9, _hoisted_4),
                        _createTextVNode(_toDisplayString(_ctx.store.getters.getCartTotal), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_icon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/checkout'))),
                    size: "large",
                    icon: _ctx.cart,
                    color: "primary"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { style: {"margin-bottom":"20px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.coupons, (coupon) => {
                    return (_openBlock(), _createBlock(_component_ion_col, {
                      size: "12",
                      key: coupon.id,
                      style: {"position":"relative"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          size: "large",
                          icon: _ctx.gift,
                          style: {"border-radius":"50px","background":"white","box-shadow":"0px 0px 5px #00000080","padding":"3px","color":"#ffd700","position":"absolute","right":"20px","top":"10px","font-size":"20px","z-index":"9"}
                        }, null, 8, ["icon"]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("img", {
                            style: {"border-radius":"20px"},
                            src: coupon.image
                          }, null, 8, _hoisted_6),
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.switchLang(coupon.descriptionEn, coupon.descriptionAr)), 1),
                          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.switchLang('Code: ' + coupon.code, coupon.code + ':كود')), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}