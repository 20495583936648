
import { defineComponent, ref, computed } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';
export default defineComponent({
  name: 'Bank',
  components: { 
     IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon
  },

  setup(){

    const switchLang = (en: string, ar: string) => {
      if(store.state.lang == 'en')
      { return en } 
      else if(store.state.lang == 'ar')
      { return ar }
    }

    const pay = () => {

      const formData = new FormData()

      formData.append('supplier_id', store.state.user.cart[0].product.user_id)
      formData.append('products', JSON.stringify(store.state.user.cart))
      
      axios({
          method: 'post',
          url:`${store.state.api}orders`,
          data: formData,
          headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'Content-Type': 'multipart/form-data'
          }
      }).then( () => {
          store.state.toastMessage = 'Product Version Created'
          store.state.toastColor = 'success'
          store.dispatch('openToast')
          store.dispatch('getClientProductsVersions')
          store.dispatch('getClientOrders')
          
          // store.state.user.cart = []

          // router.push('/')

      }).catch((errors) => {
          store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
          store.state.toastColor = 'danger'
          store.dispatch('openToast')
      })
     
    }

    return {
        store,
        router,
        switchLang,

        //functions
        pay,

        //ICONS
        cart,
        heart, 
        heartOutline,
    }
  }
})
