<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Join Requests' : 'طلبات الانضمام' }}</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <!-- <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 18px; right: 30px; padding: 0px 7px 0px 7px; z-index: 9; background: lightblue; height: 22px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white" :dir="en ? 'ltr' : 'rtl'">{{en ? 'EGP' : 'ج'}}</span>{{ store.getters.getCartTotal }}</div>
          <ion-icon @click="router.push('/checkout')" size="large" :icon="cart" color="primary"></ion-icon> -->
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid>
          <ion-row>
            <div :dir=" en ? 'ltr' : 'rtl'" class="card" size="12" v-for="jr in store.state.joinRequests" :key="jr.id" :order="order">
              <div><span class="label">{{ en ? 'Name' : 'الاسم' }}:</span> {{ jr.name }}</div>
              <div><span class="label">{{ en ? 'Number' : 'التليفون' }}:</span> {{ jr.number }}</div>
              <div><span class="label">{{ en ? 'Address' : 'العنوان' }}:</span> {{ jr.address }}</div>
              <div><span class="label">{{ en ? 'Field' : 'المجال' }}:</span> {{ jr.field }}</div>
            </div>             
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow } from '@ionic/vue';
import { cart, heart, heartOutline, arrowForward, arrowBack } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';

export default defineComponent({
  name: 'join requests',
  components: { 
     IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow
  },

  setup(){

    const en = computed(() => store.state.lang == 'en')

    onMounted(() => {
      store.dispatch('getAdminJoinRequests')
    })

    return {
      //Vue
      store,
      router,

      //Main
      en,

      //ICONS
      cart,
      heart, 
      heartOutline,
      arrowForward,
      arrowBack,
    }
  }
})
</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

.card{
  width: 100%;
  box-shadow: 0px 0px 5px #c0c0c0;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  font-weight: 700;
  color: #190F21
;
}

.label{
  color: #8759a6;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>