<template>
    <ion-col>
    <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px; height: 100%">
        <div>
        <ion-row  :dir="en ? 'ltr' : 'rtl'">
          <ion-col size="2"><img :src="order.client.logo"/></ion-col>
          <ion-col size="7" style="font-weight: 700px; font-size:32px">{{ en ? order.client.name_en : order.client.name_ar }}</ion-col>
          <ion-col size="3" style="font-weight: 700px; font-size:14px">
          <div style="padding: 5px; border-radius: 50px; border: 1px solid black; text-align: center" @click="showStatusUpdater = !showStatusUpdater" :style="[order.status == 'submitted' ? {backgroundColor: 'white'}: {},order.status == 'received' ? {backgroundColor: '#e8ff00'}: {},order.status == 'active' ? {backgroundColor: '#0083ff', color: 'white'}: {},order.status == 'delivered' ? {backgroundColor: '#00da00', color: 'white'}: {}]">{{ en ? order.status : order.status }}</div>
          <div style="font-size: 24px; position: absolute; left: 0px; z-index:10; width: 200px; text-align: center" v-if="showStatusUpdater">
              <div style="margin: 10px; padding: 5px; border-radius: 50px; border: 1px solid black; background-color: #e8ff00" @click="updateOrderStatus('received')">{{ en ? 'Received' : 'تم الاستلام' }}</div>
              <div style="margin: 10px; padding: 5px; border-radius: 50px; border: 1px solid black; background-color: #0083ff; color: white" @click="updateOrderStatus('active')">{{ en ? 'Active' : 'جاري العمل' }}</div>
              <div style="margin: 10px; padding: 5px; border-radius: 50px; border: 1px solid black; background-color: #00da00; color: white" @click="updateOrderStatus('delivered')">{{ en ? 'Delivered' : 'تم التوصيل' }}</div>
          </div>
          </ion-col>
        </ion-row>
        <ion-row  :dir="en ? 'ltr' : 'rtl'">
            <div><span style="color: #e60060">{{en ? 'Email' : 'الايميل'}}:</span><span style="color: #872bc1">{{ order.client.email }}</span></div>
        </ion-row>
        <ion-row  :dir="en ? 'ltr' : 'rtl'">
            <div><span style="color: #e60060">{{en ? 'Phone Number' : 'رقم التليفون'}}:</span><span style="color: #872bc1">{{ order.client.phone_number }}</span></div>
        </ion-row>
        <ion-row  :dir="en ? 'ltr' : 'rtl'">
            <div><span style="color: #e60060">{{en ? 'Address' : 'العنوان'}}:</span><span style="color: #872bc1">{{ order.client.address }}</span></div>
        </ion-row>
        <ion-row  :dir="en ? 'ltr' : 'rtl'">
            <div><span style="color: #e60060">{{en ? 'Additional info' : 'معلومات اخرى'}}:</span><span style="color: #872bc1">{{ order.client.additional_info }}</span></div>
        </ion-row>
        <ion-row>
            <ion-col size="6" style="color: #ddb901; font-weight: 700">{{order.created_at.slice(0,10)}} - {{order.created_at.slice(11,16)}}</ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="color: #000098;display: flex; align-items: center; justify-content: end; font-weight: 700">{{order.product_versions.reduce((acc,pv)=>acc+(pv.price*pv.pivot.quantity),0)}} {{ en ? 'EGP' : 'ج' }}</ion-col>
        </ion-row>
        <ion-row v-for="item in order.product_versions" :key="item.id" style="border-top: 1px solid #000098">
            <ion-col size="2"><div><img style="width: 50px" :src="item.product.image"/></div></ion-col>
            <ion-col size="5" style="display: flex; align-items: center; color: #000098"><div>{{en ? item.product.name_en : item.product.name_ar}}</div></ion-col>
            <ion-col size="5" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div><span style="color: black; font-size: 14px">{{item.price}} X {{ item.pivot.quantity }}</span> <br> {{item.price * item.pivot.quantity }} {{ en ? 'EGP' : 'ج' }}</div></ion-col>
        </ion-row>
        <!-- <ion-row v-if="order.status == 'submitted'">
          <ion-col>
            <ion-row>Edit</ion-row>
            <ion-row v-for="item in order.product_versions" :key="item.id" style="border-top: 1px solid #000098">
              <ion-col size="4" style="display: flex; align-items: center; color: #000098"><div>{{en ? item.product.name_en : item.product.name_ar}}</div></ion-col>
              <ion-col size="2" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div>{{ item.price}}  {{ en ? 'EGP' : 'ج' }}</div></ion-col>
              <ion-col size="2" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div>{{ item.pivot.quantity }}</div></ion-col>
              <ion-col size="2" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div>{{ item.price * item.pivot.quantity }} {{ en ? 'EGP' : 'ج' }}</div></ion-col>
              <ion-col size="2" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div>Remove</div></ion-col>
            </ion-row>
          </ion-col>
        </ion-row> -->
        </div>
    </div>
    </ion-col>

  </template>
  
  <script>
  import { defineComponent, ref, computed, onMounted } from 'vue'
  import {  IonRow, IonCol } from '@ionic/vue';
  import { cart, heart, heartOutline } from 'ionicons/icons';
  // import Loading from '../Loading.vue'
  import store from '@/store/index';
  import router from '@/router/index';
  import axios from 'axios'
  
  export default defineComponent({
    name: 'Orders',
    components: { 
       IonRow, IonCol
    },
    props: ['order'],
  
    setup(props){
  
      const en = computed(() => store.state.lang == 'en')
  
      onMounted(() => {
        store.dispatch('getSupplierOrders')
      })

      const showStatusUpdater = ref(false)
  
      const updateOrderStatus = (status) => {
        showStatusUpdater.value = false
  
        const formData = new FormData()
  
        formData.append("status", status);
        formData.append("_method", 'PATCH');
        
        axios({
            method: 'post',
            url:`${store.state.api}orders/${props.order.id}`,
            data: formData,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'Content-Type': 'multipart/form-data'
            }
        }).then( () => {
            store.state.editProductFormOpened = false
            store.state.toastMessage = 'Order Status Updated!'
            store.state.toastColor = 'success'
            store.dispatch('openToast')
            store.dispatch('getSupplierOrders')
            
  
            location.reload()
            
        }).catch((errors) => {
            store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })
  
      }
  
      return {
        //Vue
        store,
        router,
  
        //Main
        en,

        //Data
        showStatusUpdater,
  
        //Functions
        updateOrderStatus,
  
        //ICONS
        cart,
        heart, 
        heartOutline,
      }
    }
  })
  </script>
  
  <style scoped>
  
  .fade-in {
    animation: fade-in 5s ease-out forwards;
  }
  
  @keyframes fade-in{
    0%{
      opacity: 0
    }
    50%{
      opacity: 0
    }
    100%{
      opacity: 1
    }
  }
  </style>