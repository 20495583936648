<template>
  <ion-page  v-if="store.state.activeProduct">
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-back-button text="" v-if="!store.state.editProductFormOpened" color="primary" defaultHref="/admin/categories"></ion-back-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">Goodz</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <!-- <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 20px; right: 30px; padding: 0px 5px; z-index: 9; background: lightblue; height: 20px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white">EGP</span>{{ store.getters.getCartTotal }}</div> -->
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">

        <ion-grid style="margin-bottom: 20px" :dir="store.state.lang == 'ar' ? 'rtl' : 'ltr'">
          <ion-row>
            <ion-col>
              <div v-if="!store.state.editProductFormOpened" style="margin: 5px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px">
                <div style="display: flex; justify-content: space-between;; padding: 10px">
                  <!-- <ion-icon @click="deleteProduct()" size="large" :icon="trash" color="danger"></ion-icon> -->
                  <ion-icon @click="editProduct()" size="large" :icon="create" color="success"></ion-icon>
                </div>
                <div style="padding: 5px">
                  <img style="border-radius: 20px" :src="store.state.activeProduct.image">
                </div>
                <div style="text-align: center">
                  <div>{{switchLang(store.state.activeProduct.name_en, store.state.activeProduct.name_ar)}}</div>
                  <div>{{switchLang(store.state.activeProduct.description_en, store.state.activeProduct.description_ar)}}</div>

                </div>
              </div>

              <ProductForm v-if="store.state.editProductFormOpened" :entryType="{process: 'Edit'}"/>

            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { trash, create } from 'ionicons/icons';
import { useRoute } from 'vue-router'
import store from '@/store/index';
import router from '@/router/index';
import ProductForm from '@/views/Admin/Pages/ProductForm.vue'
import axios from 'axios'

    const switchLang = (en, ar) => {
      if(store.state.lang == 'en')
      { return en } 
      else if(store.state.lang == 'ar')
      { return ar }
    }

    onMounted(() => {
      store.dispatch('getSupplierProductAndVersions', route.params.id)
    })

    const route = useRoute()
    // const product = computed(() => store.state.products.data.find(p => p.id == parseInt(route.params.id, 10)))
    const quantity = ref(1)

    const editProduct = () => {
      store.state.productToEdit = {...store.state.activeProduct}
      store.state.productToEditImage = store.state.productToEdit.image
      store.state.editProductFormOpened = true
    }

    const deleteProduct = () => {
      axios({
          method: 'delete',
          url:`${store.state.api}products/${route.params.id}`,
          headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'Content-Type': 'multipart/form-data'
          }
      }).then( () => {
          store.state.toastMessage = 'Product Deleted!'
          store.state.toastColor = 'success'
          store.dispatch('openToast')
          store.dispatch('getAdminProducts',{page: 1, subcategory_id: route.params.id})

          router.push({name:'admin products'})

          // location.reload()
          
      }).catch((errors) => {
          store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
          store.state.toastColor = 'danger'
          store.dispatch('openToast')
      })
    }

</script>

<style scoped>

</style>