
    import { defineComponent, onMounted, computed } from 'vue'
    import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonRow, IonCol } from '@ionic/vue'
    // import { cart, gift } from 'ionicons/icons';
    
    import store from '@/store/index';
    
    export default defineComponent({
        name: 'admin dashboard',
    
        components: {
            IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonRow, IonCol
        },
    
        setup(){
    
          const switchLang = (en: string, ar: string) => {
            if(store.state.lang == 'en')
            { return en } 
            else if(store.state.lang == 'ar')
            { return ar }
          }

          const en = computed(() => store.state.lang == 'en')

          onMounted(()=>{
            store.dispatch('getAdminSuppliers')
            store.dispatch('getAdminClients')
            store.dispatch('getAdminOrders')
          })
          
            return{
                //vue
                store,

                //Main
                en,
    
                //functions
                switchLang,
    
                //icons

    
            }
        }
    })
    