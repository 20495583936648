<template>
    <ion-page>
      <ion-header :translucent="true" style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff40">
          <ion-buttons slot="start">
            <ion-back-button text="" color="primary" defaultHref="/admin/categories"></ion-back-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div v-if="store.state.categories.find(cat => cat.id == route.params.cid)" style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? store.state.categories.find(cat => cat.id == route.params.cid).name_en : store.state.categories.find(cat => cat.id == route.params.cid).name_ar }}</div></ion-title>
          <ion-buttons v-if="store.state.loggedIn" slot="end" style="position: relative">
            <!-- <ion-icon @click="router.push('/client/checkout')" size="large" :icon="cart" color="primary"></ion-icon> -->
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
  
      <ion-content :fullscreen="true">
        <ion-grid>
            <ion-row v-if="store.state.categories.find(cat => cat.id == route.params.cid) && store.state.categories.find(cat => cat.id == route.params.cid).subcategories.length > 0">
              <CategoryButton v-for="c in store.state.categories.find(cat => cat.id == route.params.cid).subcategories" :key="c.id" :category="c" :path="'/admin/category/'+route.params.cid+'/subcategory/'+c.id"/>
            </ion-row>
            <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #E60060">
              {{en ? 'unfortunately there are no products matching your choice' : 'للأسف لا توجد منتجات الان توافق اختيارك'}}
            </ion-row>
        </ion-grid>
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { IonButtons, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/vue';
import { cart, heart, heartOutline, arrowBack, arrowForward } from 'ionicons/icons';
import store from '@/store/index';
import { useRoute } from 'vue-router'
import router from '@/router/index';
import CategoryButton from '@/views/Components/CategoryButton.vue'

  const route = useRoute()

  const en = computed(() => store.state.lang == 'en')

</script>

<style scoped>

.header-fade-in {
animation: header-fade-in 2s ease-in forwards;
}

@keyframes header-fade-in{
0%{
  opacity: 0
}
75%{
  opacity: 0
}
100%{
  opacity: 1
}
}

</style>