<template>
    <ion-row class="card" :dir="en ? 'ltr' : 'rtl'">
        <ion-col size="8" style="position: relative">
            <div>
            <div class="card-header" style="" :dir="en ? 'ltr' : 'rtl'">
                <div>{{ `${en ? user.name_en : user.name_ar}` }} </div>
                <div>{{ `ID: ${user.id}` }} </div>
            </div>
            <div class="card-body" :dir="en ? 'ltr' : 'rtl'">
                <div><span>{{en ? 'Status' : 'الحالة'}}: </span><span>{{ user.status }}</span></div>
                <div><span>{{en ? 'Email' : 'الايميل'}}:</span><span>{{ user.email }}</span></div>
                <div><span>{{en ? 'Phone Number' : 'رقم التليفون'}}:</span><span>{{ user.phone_number }}</span></div>
            </div>
            </div>
        </ion-col>
        <ion-col size="4">
            <div v-if="user.logo && user.logo.split('storage')[1]">
                <img :src="user.logo"/>
            </div>
        </ion-col>
    </ion-row>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import {IonRow, IonCol } from '@ionic/vue'
import store from '@/store/index';

    export default defineComponent({
        name: 'admin user card',

        props: ['user'],
    
        components: { 
            IonRow, IonCol
        },

        setup(props){
            const en = computed(() => store.state.lang == 'en')

            return{
                //Main
                en,

                //Functions

                //Icons
            }
        }
    })
</script>

<style scoped>
.card{
    border-radius: 20px; 
    box-shadow: 0px 0px 5px #00000080; 
    padding: 20px;
    margin: 5px;
    /* font-weight: 700; */
}

.card-header{
    color: #e60060;
    font-size: 32px;
}

.card-body{
    color: #91148d;
}
</style>