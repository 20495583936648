<template>

  <ion-col size="12">
    <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px; height: 100%">
      <div>
        <ion-row  :dir="en ? 'ltr' : 'rtl'">
          <ion-col size="5">
            <ion-row @click="toggleSupplierInfo">
              <ion-col size="12" style="display: flex; justify-content: center;"><img style="height: 50px" :src="order.product_versions[0].supplier.logo"/></ion-col>
              <ion-col size="12" style="display: flex; justify-content: center;">{{ en ? order.product_versions[0].supplier.name_en : order.product_versions[0].supplier.name_ar }}</ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="2" style="display: flex; justify-content: center; align-items: center;">
            <ion-icon style="color: #e60060" size="large" :icon="en ? arrowForward : arrowBack"></ion-icon>
          </ion-col>
          <ion-col size="5">
            <ion-row @click="toggleClientInfo">
              <ion-col size="12" style="display: flex; justify-content: center;"><img style="height: 50px" :src="order.client.logo"/></ion-col>
              <ion-col size="12" style="display: flex; justify-content: center;">{{ en ? order.client.name_en : order.client.name_ar }}</ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row v-if="showSupplierInfo">
          <ion-col>
            <ion-row  :dir="en ? 'ltr' : 'rtl'">
                <div><span style="color: #e60060">{{en ? 'Email' : 'الايميل'}}:</span><span style="color: #872bc1">{{ order.product_versions[0].supplier.email }}</span></div>
            </ion-row>
            <ion-row  :dir="en ? 'ltr' : 'rtl'">
                <div><span style="color: #e60060">{{en ? 'Phone Number' : 'رقم التليفون'}}:</span><span style="color: #872bc1">{{ order.product_versions[0].supplier.phone_number }}</span></div>
            </ion-row>
            <ion-row  :dir="en ? 'ltr' : 'rtl'">
                <div><span style="color: #e60060">{{en ? 'Address' : 'العنوان'}}:</span><span style="color: #872bc1">{{ order.product_versions[0].supplier.address }}</span></div>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row v-if="showClientInfo">
          <ion-col>
            <ion-row  :dir="en ? 'ltr' : 'rtl'">
                <div><span style="color: #e60060">{{en ? 'Email' : 'الايميل'}}:</span><span style="color: #872bc1">{{ order.client.email }}</span></div>
            </ion-row>
            <ion-row  :dir="en ? 'ltr' : 'rtl'">
                <div><span style="color: #e60060">{{en ? 'Phone Number' : 'رقم التليفون'}}:</span><span style="color: #872bc1">{{ order.client.phone_number }}</span></div>
            </ion-row>
            <ion-row  :dir="en ? 'ltr' : 'rtl'">
                <div><span style="color: #e60060">{{en ? 'Address' : 'العنوان'}}:</span><span style="color: #872bc1">{{ order.client.address }}</span></div>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6" style="color: #ddb901; font-weight: 700">{{order.created_at.slice(0,10)}} - {{order.created_at.slice(11,16)}}</ion-col>
          <ion-col size="1"></ion-col>
          <ion-col size="5" style="color: #000098;display: flex; align-items: center; justify-content: end; font-weight: 700">{{order.product_versions.reduce((acc,pv)=>acc+(pv.price*pv.pivot.quantity),0)}} {{ en ? 'EGP' : 'ج' }}</ion-col>
        </ion-row>
        <ion-row v-for="item in order.product_versions" :key="item.id" style="border-top: 1px solid #000098">
            <ion-col size="2"><div><img style="width: 50px" :src="item.product.image"/></div></ion-col>
            <ion-col size="5" style="display: flex; align-items: center; color: #000098"><div>{{en ? item.product.name_en : item.product.name_ar}}</div></ion-col>
            <ion-col size="5" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div><span style="color: black; font-size: 14px">{{item.price}} X {{ item.pivot.quantity }}</span> <br> {{item.price * item.pivot.quantity }} {{ en ? 'EGP' : 'ج' }}</div></ion-col>
        </ion-row>
      </div>
    </div>
  </ion-col>

</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline, arrowForward, arrowBack } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';

export default defineComponent({
  name: 'admin orders',
  components: { 
     IonRow, IonCol, IonIcon
  },

  props: ['order'],

  setup(){

    const en = computed(() => store.state.lang == 'en')

    onMounted(() => {
      store.dispatch('getAdminOrders')
    })

    const showSupplierInfo = ref(false)
    const showClientInfo = ref(false)

    const toggleSupplierInfo = () => {
      showClientInfo.value =! showClientInfo.value
      showSupplierInfo.value = false
    }
    const toggleClientInfo = () => {
      showSupplierInfo.value =! showSupplierInfo.value
      showClientInfo.value = false
    }

    return {
      //Vue
      store,
      router,

      //Main
      en,

      //Data
      showSupplierInfo,
      showClientInfo,

      //Functions
      toggleSupplierInfo,
      toggleClientInfo,

      //ICONS
      cart,
      heart, 
      heartOutline,
      arrowForward,
      arrowBack,
    }
  }
})
</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>