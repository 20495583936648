import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6df84f14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","text-align":"center","font-size":"32px","font-weight":"700","color":"#E60060"} }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "card-header" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-header" }
const _hoisted_13 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        translucent: true,
        style: {"background-image":"linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { style: {"--background":"#ffffff00"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "primary" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, {
                size: "large",
                style: {"text-align":"center","margin-top":"10px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.en ? 'Dashboard' : 'لوحة التحكم'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, {
                slot: "end",
                style: {"position":"relative"}
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { style: {"margin-bottom":"20px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    style: {"position":"relative"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.en ? 'All Products' : 'جميع المنتجات'), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.store.state.products.length), 1)
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.en ? 'All Suppliers' : 'جميع الموردين'), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.store.state.suppliers.length), 1)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.en ? 'All Clients' : 'جميع العملاء'), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.store.state.clients.length), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.en ? 'All Orders' : 'جميع الطلبات'), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.store.state.orders.length), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}