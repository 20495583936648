<template>
    <ion-row>
        <ion-col>
            <div>
                <img src="assets/images/welcome.png" style="border-radius: 30px " :class="catPopClass"/>
            </div>
        </ion-col>
    </ion-row>
</template>

<script setup>
import { computed, ref, defineProps } from 'vue'
import { IonRow, IonCol } from '@ionic/vue';
import store from '@/store/index';
import router from '@/router/index';

    const en = computed(() => store.state.lang == 'en')
    
    const catPopClass = ref()

    if(store.state.intro){
        setTimeout(() => {
            store.state.intro = false
            catPopClass.value = 'popOver'
        }, 1000)
    } else {
            catPopClass.value = 'popOver'
    }

</script>

<style scoped>

    .popOver {
        -webkit-animation: bummer 0.5s;
        animation: bummer 0.5s;
        -webkit-transform: scale(0,0); 
        transform: scale(0,0);
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                            close after the animation completes */
    }

    @-webkit-keyframes bummer {
      100% {
          -webkit-transform: scale(1,1); 
      }
    }
    
    @keyframes bummer {
        100% {
            transform: scale(1,1); 
        }
    }

</style>