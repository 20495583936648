
  import { defineComponent, ref, computed } from 'vue'
  import {  IonContent, IonPage } from '@ionic/vue';
  import { cart, heart, heartOutline, call } from 'ionicons/icons';
  // import Loading from '../Loading.vue'
  import store from '@/store/index';
  import router from '@/router/index';
  
  export default defineComponent({
    name: 'ContactUs',
    components: { 
       IonContent, IonPage
    },
  
    setup(){
  
     
  
      return {
        
      }
    }
  })
  