<template>
  
    <div style="display: flex; justify-content: center">
      <div v-if="store.state.loggedIn" style="display: flex; justify-content: space-around; align-items: center; width: 100%">
        <div  v-if="!checkout">{{productVersion.price}} {{switchLang('EGP', 'ج')}}</div>
        <div style="display: flex;">
            <div style="padding: 5px; display: flex; align-items: center">
              <div @click="changeQuantity(false)" style="font-size: 50px; line-height: 10px; color: #000098; margin-top: 5px">-</div>
            </div>
            <ion-input @ionChange="updateCart()" type="number" style="width: 50px; font-size: 25px; border-radius: 50px; text-align: center; color: #E60060; margin: 5px 5px 0px 0px; --padding-start: 0px;" v-model="quantity" ></ion-input>
            <div style="padding: 5px; display: flex; align-items: center">
              <div @click="changeQuantity(true)" style="font-size: 40px; line-height: 10px; color: #000098">+</div>
            </div>
        </div>
        <div class="btn" v-if="!checkout" @click="addToCart()" style="background: linear-gradient(#8757AD,#FE5995); padding: 10px 20px 5px 20px; margin: 10px; border-radius: 20px 0px 20px 0px; text-align: center;  color: white; display: flex; align-content: center;">
          <div>{{ quantity * productVersion.price}}</div>
          <ion-icon size="large" :icon="cart"></ion-icon>
        </div>
      </div>
      <div v-else>
        <div  class="btn" v-if="!checkout" @click="router.push('/login')" style="background: lightgreen; padding: 10px; margin: 10px; border-radius: 20px; text-align: center">{{ en ? 'Login to order' : 'سجل دخولك للطلب' }}</div>
      </div>
    </div>
    
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'
import { IonInput, IonIcon } from '@ionic/vue';
import { cart } from 'ionicons/icons';
import store from '@/store/index';
import router from '@/router/index';


  const props = defineProps({
    productVersion: Object,
    checkout: Boolean
  })

  const en = computed(() => store.state.lang == 'en')

  const switchLang = (en, ar) => {
    if(store.state.lang == 'en')
    { return en } 
    else if(store.state.lang == 'ar')
    { return ar }
  }

  const quantity = ref(props.checkout ? store.state.user.cart[props.productVersion.supplier.id].items.find((cartItem) => cartItem.productVersion.id == props.productVersion.id).quantity : 1)

  const changeQuantity = (add) => {
    if(!props.checkout){
      if(add){
          quantity.value++ 
      }else if(!add && quantity.value > 1){
          quantity.value--
      }
    } else if(props.checkout){
      if(add){
        quantity.value++ 
        // store.state.user.cart[props.productVersion.supplier.id].items.find((cartItem) => cartItem.productVersion.id == props.productVersion.id).quantity++ 
        // localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
      }else if(!add && quantity.value > 1){
        quantity.value--
        // store.state.user.cart[props.productVersion.supplier.id].items.find((cartItem) => cartItem.productVersion.id == props.productVersion.id).quantity--
        // localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
      }
    }
  }

  const updateCart = () => {
    if(props.checkout){
      if(quantity.value == 0 || quantity.value == '' || isNaN(quantity.value)){
        quantity.value = 1
      }
      store.state.user.cart[props.productVersion.supplier.id].items.find((cartItem) => cartItem.productVersion.id == props.productVersion.id).quantity = quantity.value
      localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
    }
  }

  const addToCart = () => {
    const cartItem = store.state.user.cart[props.productVersion.supplier.id] ? store.state.user.cart[props.productVersion.supplier.id].items.find((cartItem) => cartItem.productVersion.id == props.productVersion.id) : null
    // if(store.state.user.cart[0] && props.productVersion.supplier.id != store.state.user.cart[0].productVersion.supplier.id){
    //   store.dispatch('cannotAddDifferentSupplierProductAlert')
    // }else{
      if(cartItem){
        cartItem.quantity = cartItem.quantity + quantity.value
        localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
      } else {
        if(!store.state.user.cart[props.productVersion.supplier.id]){
          store.state.user.cart[props.productVersion.supplier.id] = {
            supplier: props.productVersion.supplier,
            items:[]
          }
        }
        store.state.user.cart[props.productVersion.supplier.id].items.push({product: store.state.activeProduct, productVersion: props.productVersion, quantity: quantity.value})
        localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
      }
      localStorage.setItem('user', JSON.stringify(store.state.user))
    // }
  }

    
</script>

<style scoped>

.btn:active {
  transform: scale(0.9)
}



</style>