<template>
    <ion-page>
      <ion-header :translucent="true" style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff40">
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Categories' : 'الفئات' }}</div></ion-title>
          <ion-buttons v-if="store.state.loggedIn" slot="end" style="position: relative">
            <!-- <ion-icon @click="router.push('/client/checkout')" size="large" :icon="sync" color="primary"></ion-icon> -->
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
  
      <ion-content :fullscreen="true">
  
        <search v-if="!store.state.editProductFormOpened" admin/>

        <ion-grid v-if="!store.state.editProductFormOpened">
            <ion-row style="margin-bottom: 50px">
              <CategoryButton v-for="c in store.state.categories" :key="c.id" :category="c" :path="'/admin/category/' + c.id"/>
            </ion-row>
        </ion-grid>
        <div v-if="store.state.editProductFormOpened">
          <ProductForm v-if="store.state.editProductFormOpened" :entryType="{process: 'New'}"/>
        </div>

        <div v-if="!store.state.editProductFormOpened" style="position: fixed; bottom: 0px; right: 20px">
          <div @click="store.state.editProductFormOpened = true" style="color: lightgreen; font-size: 72px;"><ion-icon :icon="addCircle"></ion-icon></div>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { sync, heart, heartOutline, arrowBack, arrowForward, addCircle } from 'ionicons/icons';
import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios'
import ProductForm from '@/views/Admin/Pages/ProductForm.vue'
import CategoryButton from '@/views/Components/CategoryButton.vue'
import Search from '@/views/Components/Search.vue'

  const en = computed(() => store.state.lang == 'en')

</script>
  
<style scoped>

  .header-fade-in {
  animation: header-fade-in 2s ease-in forwards;
  }

  @keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
  }

</style>