<template>
  <ion-page>
      <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff00">
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Dashboard' : 'لوحة التحكم' }}</div></ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        
      <ion-grid style="margin-bottom: 20px;">

        <ion-row>
            <ion-col size="12" style="position: relative">
                <div class="card">
                  <div class="card-header">
                    Active Orders
                  </div>
                  <div class="card-body">
                    {{ store.state.orders.filter(o=>o.status == 'received').length }}
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    All Orders
                  </div>
                  <div class="card-body">
                    {{ store.state.orders.length }}
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    All Clients
                  </div>
                  <div class="card-body">
                    {{ clients.length }}
                  </div>
                </div>
            </ion-col>
        </ion-row>
      </ion-grid>
  
      </ion-content>
      </ion-page>
  </template>
  
  <script>
  import { defineComponent, computed } from 'vue'
  import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonRow, IonCol } from '@ionic/vue'
  // import { cart, gift } from 'ionicons/icons';
  
  import store from '@/store/index';
  
  export default defineComponent({
      name: 'supplier dashboard',
  
      components: {
          IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonRow, IonCol
      },
  
      setup(){
  
        const switchLang = (en, ar) => {
          if(store.state.lang == 'en')
          { return en } 
          else if(store.state.lang == 'ar')
          { return ar }
        }

        const en = computed(() => store.state.lang == 'en')

        const clients = computed(() => {

          let result = {}
          store.state.orders.map(el=>{
            result[el.client.id] = el.client
          })
          return Object.values(result)

        })
        
          return{
              //vue
              store,
  
              //Main
              en,
              switchLang,

              //Data
              clients,
  
              //icons

  
          }
      }
  })
  </script>

<style scoped>

.card{
border-radius: 20px; 
box-shadow: 0px 0px 5px #00000080; 
padding: 20px;
text-align: center;
font-size: 24px;
font-weight: 700;
margin: 10px;
}

.card-header{
color: #e60060;
}

.card-body{
color: #91148d;
}

</style>