<template>
    <ion-page>
      <ion-header :class="store.state.headerClass" :translucent="true" style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff40">
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Categories' : 'الفئات' }}</div></ion-title>
          <ion-buttons v-if="store.state.loggedIn" slot="end" style="position: relative">
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 18px; right: 30px; padding: 0px 7px 0px 7px; z-index: 9; background: lightblue; height: 22px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white" :dir="en ? 'ltr' : 'rtl'">{{en ? 'EGP' : 'ج'}}</span>{{ store.getters.getCartTotal }}</div>
            <ion-icon @click="router.push('/client/checkout')" size="large" :icon="cart" color="primary"></ion-icon>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
  
      <ion-content :fullscreen="true">
        <transition name="overlay">
            <loading v-if="store.state.intro" style="z-index: 99; height: 100%; position: fixed; top: 0px"/>
        </transition>
  
        <ion-grid>
            
            <search/>
            
            <ClientHomepageSlider/>

            <ion-row style="margin-bottom: 50px">
                <CategoryButton v-for="c in store.state.categories" :key="c.id" :category="c" :path="'/client/category/' + c.id"/>
            </ion-row>

        </ion-grid>
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { ref, computed, onMounted } from 'vue'
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonInput } from '@ionic/vue';
import { cart, heart, heartOutline, arrowBack, arrowForward } from 'ionicons/icons';
import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import CategoryButton from '@/views/Components/CategoryButton.vue'
import ClientHomepageSlider from '@/views/Components/ClientHomepageSlider.vue'
import Search from '@/views/Components/Search.vue'

  const en = computed(() => store.state.lang == 'en')

  setTimeout(() => {
    store.state.headerClass = ''
  }, 1000)
  
  
</script>

<style scoped>

.header-fade-in {
  animation: header-fade-in 2s ease-in forwards;
}

@keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}

.btn:active {
    transform: scale(0.9)
}

</style>