<template>
    <ion-page>
      <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff00">
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Contact Us' : 'تواصلوا معنا' }}</div></ion-title>
          <ion-buttons slot="end" style="position: relative">
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 18px; right: 30px; padding: 0px 7px 0px 7px; z-index: 9; background: lightblue; height: 22px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white" :dir="en ? 'ltr' : 'rtl'">{{en ? 'EGP' : 'ج'}}</span>{{ store.getters.getCartTotal }}</div>
            <ion-icon @click="router.push('/client/checkout')" size="large" :icon="cart" color="primary"></ion-icon>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true" style="position: relative">
  
          <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
            <div @click="showConfirmDeletion()" style="width: 50%; text-align: center; padding: 5px; background: red; color: white; font-weight: 700; border-radius: 20px">{{ en ? 'Delete account' : 'مسح الحساب' }}</div>
          </div>
  
      </ion-content>
    </ion-page>
  </template>
  
  <script>
  import { defineComponent, ref, computed } from 'vue'
  import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonIcon, alertController } from '@ionic/vue';
  import { cart, heart, heartOutline, call } from 'ionicons/icons';
  // import Loading from '../Loading.vue'
  import store from '@/store/index';
  import router from '@/router/index';
  import axios from 'axios';


  export default defineComponent({
    name: 'ContactUs',
    components: { 
       IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonIcon
    },
  
    setup(){

        const en = computed(() => store.state.lang == 'en')


        const logout = () => {
            localStorage.clear()
            store.state.loggedIn = false
            store.state.user.role.name = 'guest'
            router.push('/login')
        }

        const deleteUser = () => {
            axios({
                method: 'delete',
                url:`${store.state.api}users/${store.state.user.id}`,
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'multipart/form-data'
                }
            }).then( () => {
                store.state.toastMessage = 'User Deleted!'
                store.state.toastColor = 'success'                
            }).catch((errors) => {
                store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
                store.state.toastColor = 'danger'
                store.dispatch('openToast')
            })
        }
        
        const showConfirmDeletion = async () => {
        const alert = await alertController.create({
            header: en.value ? 'Are you sure you want to delete your account? This is irrreversible' : 'هل انت متأكد انك تريد مسح حسابك؟ هذه الخطوة لا يمكن الرجوع فيها',
            buttons: [
                {
                text: en.value ? 'Cancel' : 'رجوع',
                role: 'cancel',
                handler: () => {
    //
                },
                },
                {
                text: en.value ? 'Delete Account' : 'مسح الحساب',
                role: 'confirm',
                handler: () => {
                    console.log('deleted')
                    deleteUser()
                    logout()
                },
                },
            ],
            });

            await alert.present();

            const { role } = await alert.onDidDismiss();
        };

  
        return {
            store,
            router,
            //main
            en,

            // function
            showConfirmDeletion,

            //icons
            cart,
        }
    }
  })
  </script>
  
  <style scoped>
  
  </style>