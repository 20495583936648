
import { defineComponent, ref, computed } from 'vue'
import {  IonButton, IonImg, IonTextarea, IonInput, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline, call } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';

export default defineComponent({
  name: 'ContactUs',
  components: { 
     IonButton, IonImg, IonTextarea, IonInput, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon
  },

  setup(){

    const en = computed(() => store.state.lang == 'en')

    const switchLang = (en: string, ar: string) => {
      if(store.state.lang == 'en')
      { return en } 
      else if(store.state.lang == 'ar')
      { return ar }
    }

    const contactUs = () => {
        router.push('/')
    }

    return {
        //vue
        store,
        router,
        
        //Main
        en,
        switchLang,

        //functions
        contactUs,

        //ICONS
        cart,
        heart, 
        heartOutline,
        call,
    }
  }
})
