<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">{{ en ? 'Orders' : 'الطلبات' }}</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <ion-icon @click="store.dispatch('getSupplierOrders')" size="large" :icon="sync" color="primary"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid>
          <ion-row v-if="store.state.orders && store.state.orders.length > 0">
            <ion-col size="12" v-for="order in store.state.orders" :key="order.id">
              <div v-if="order && order.product_versions && order.product_versions[0]" style="margin: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px 0px 20px 0px; height: 100%">
                <div>

                  <ion-row  :dir="en ? 'ltr' : 'rtl'" style="border: 1px solid #190f21; background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); color: white; text-shadow: 0px 0px 5px #000; border-radius: 20px 0px 20px 0px; height: 52px; ">
                    <ion-col size="2" class="ion-no-margin ion-no-padding"><img :src="order.product_versions[0].supplier.logo" style="height: 50px; border-radius: 20px 0px 20px 0px"/></ion-col>
                    <ion-col size="7">{{ en ? order.product_versions[0].supplier.name_en : order.product_versions[0].supplier.name_ar }}</ion-col>
                    <ion-col size="3" style="font-weight: 700px; font-size:14px">
                    <div style="padding: 5px; border-radius: 50px; border: 1px solid black; text-align: center" :style="[order.status == 'submitted' ? {backgroundColor: 'white'}: {},order.status == 'received' ? {backgroundColor: '#e8ff00'}: {},order.status == 'active' ? {backgroundColor: '#0083ff', color: 'white'}: {},order.status == 'delivered' ? {backgroundColor: '#00da00', color: 'white'}: {}]">{{ en ? order.status : order.status }}</div>
                    <div style="font-size: 24px; position: absolute; left: 0px; z-index:10; width: 200px; text-align: center" v-if="showStatusUpdater">
                        <div style="margin: 10px; padding: 5px; border-radius: 50px; border: 1px solid black; background-color: greenyellow" @click="updateOrderStatus('Edit Accepted', order)">{{ en ? 'Accept' : 'موافقة' }}</div>
                        <div style="margin: 10px; padding: 5px; border-radius: 50px; border: 1px solid black; background-color: red; color: white" @click="updateOrderStatus('Edit Rejected', order)">{{ en ? 'Reject' : 'رفض' }}</div>
                    </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6" style="color: #ddb901; font-weight: 700">{{order.created_at.slice(0,10)}} - {{order.created_at.slice(11,16)}}</ion-col>
                    <ion-col size="1"></ion-col>
                    <ion-col size="5" style="color: #000098;display: flex; align-items: center; justify-content: end; font-weight: 700">{{order.product_versions.reduce((acc,pv)=>acc+(pv.price*pv.pivot.quantity),0)}} {{ en ? 'EGP' : 'ج' }}</ion-col>
                  </ion-row>
                  <ion-row v-for="item in order.product_versions" :key="item.id" style="border-top: 1px solid #000098">
                      <ion-col size="2"><div><img style="width: 50px" :src="item.product.image"/></div></ion-col>
                      <ion-col size="5" style="display: flex; align-items: center; color: #000098"><div>{{en ? item.product.name_en : item.product.name_ar}}</div></ion-col>
                      <ion-col size="5" style="display: flex; align-items: center; justify-content: end; color: #E60060"><div><span style="color: black; font-size: 14px">{{item.price}} X {{ item.pivot.quantity }}</span> <br> {{item.price * item.pivot.quantity }} {{ en ? 'EGP' : 'ج' }}</div></ion-col>
                  </ion-row>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #E60060">
            {{ en ? 'Your orders will appear here' : 'طلباتك ستظهر هنا'}}
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { sync, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';

export default defineComponent({
  name: 'Orders',
  components: { 
     IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon
  },

  setup(){

    const en = computed(() => store.state.lang == 'en')

    onMounted(() => {
      store.dispatch('getClientOrders')
    })

    const showStatusUpdater = ref(false)
  
    const updateOrderStatus = (status, order) => {
      showStatusUpdater.value = false

      const formData = new FormData()

      formData.append("status", status);
      formData.append("_method", 'PATCH');
      
      axios({
          method: 'post',
          url:`${store.state.api}orders/${order.id}`,
          data: formData,
          headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'Content-Type': 'multipart/form-data'
          }
      }).then( () => {
          store.state.editProductFormOpened = false
          store.state.toastMessage = 'Order Status Updated!'
          store.state.toastColor = 'success'
          store.dispatch('openToast')
          store.dispatch('getSupplierOrders')
          

          location.reload()
          
      }).catch((errors) => {
          store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
          store.state.toastColor = 'danger'
          store.dispatch('openToast')
      })

    }

    return {
      //Vue
      store,
      router,

      //Main
      en,

      //DATA
      updateOrderStatus,

      //functions
      showStatusUpdater,

      //ICONS
      sync,
      heart, 
      heartOutline,
    }
  }
})
</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>