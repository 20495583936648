<template>
  <ion-page  v-if="store.state.activeProduct">
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-back-button text="" v-if="!store.state.editProductVersionFormOpened" color="primary" defaultHref="/client/categories"></ion-back-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #E60060">Goodz</div></ion-title>
        <ion-buttons v-if="store.state.loggedIn" slot="end" style="position: relative">
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 18px; right: 30px; padding: 0px 7px 0px 7px; z-index: 9; background: lightblue; height: 22px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white" :dir="en ? 'ltr' : 'rtl'">{{en ? 'EGP' : 'ج'}}</span>{{ store.getters.getCartTotal }}</div>
          <ion-icon @click="router.push('/client/checkout')" size="large" :icon="cart" color="primary"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-grid v-if="store.state.activeProduct && Object.keys(store.state.activeProduct).length > 0" style="margin-bottom: 20px" :dir="store.state.lang == 'ar' ? 'rtl' : 'ltr'">

        <ion-row>
          <ion-col>
            <div v-if="!store.state.editProductVersionFormOpened" style="margin: 5px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px 0px 20px 0px">

              <div style="padding: 5px; display: flex; justify-content: center">
                <img style="border-radius: 20px; height:300px; object-fit: contain" :src="store.state.activeProduct.image">
              </div>

              <div style="text-align: center">
                <div style="color: #D16BA5; font-size: 24px">{{switchLang(store.state.activeProduct.name_en, store.state.activeProduct.name_ar)}}</div>
                <div>{{switchLang(store.state.activeProduct.description_en, store.state.activeProduct.description_ar)}}</div>
              </div>

            </div>

            <ion-row v-if="store.state.activeProduct.productVersions.length > 0">
              <ion-col>
                <div v-for="pv in store.state.activeProduct.productVersions.filter(p => p.status == 'active')" :key="pv.id" style="box-shadow: 0px 0px 5px #c0c0c0; margin: 5px; padding: 0px; border-radius: 20px 0px 20px 0px">
                  <div style="; border: 1px solid #190f21; background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); color: white; text-shadow: 0px 0px 5px #000; border-radius: 20px 0px 20px 0px; height: 52px; text-align: center; padding: 0px; display: flex; justify-content: start" :dir="en ? 'ltr' : 'rtl'">
                    <div><img style="height: 50px; border-radius: 20px 0px 20px 0px;" :src="pv.supplier.logo"/></div>
                    <div style="padding: 10px">{{switchLang(pv.supplier.name_en, pv.supplier.name_ar)}}</div>
                  </div>
                  <div style="text-align: center; ">
                    <quantity-counter :productVersion="pv" :checkout="false"/>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #E60060">
              {{switchLang('unfortunately there are no suppliers providing this product', 'للأسف لا يوجد حاليا موردون يوفرون هذا المنتج')}}
            </ion-row>


          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart } from 'ionicons/icons';
import { useRoute } from 'vue-router'
import store from '@/store/index';
import router from '@/router/index';
import QuantityCounter from '../Components/QuantityCounter.vue'
import axios from 'axios'

export default defineComponent({
  name: 'Client Product Version',
  components: { QuantityCounter,
    IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon
  },

  setup(){

    onMounted(() => {
      store.dispatch('getClientProductAndVersions', route.params.id)
    })

    const en = computed(() => store.state.lang == 'en')

    const switchLang = (en, ar) => {
      if(store.state.lang == 'en')
      { return en } 
      else if(store.state.lang == 'ar')
      { return ar }
    }

    const route = useRoute()
    // const store.state.activeProduct.data = computed(() => store.state.productsVersions.find(p => p.id == parseInt(route.params.id, 10)))

    const editProductVersion = () => {
      store.state.productVersionToEdit = {...store.state.productsVersions.find(p => p.id == parseInt(route.params.id, 10))}
      store.state.editProductVersionFormOpened = true
    }

    const deleteProductVersion = () => {
      axios({
          method: 'delete',
          url:`${store.state.api}products/${route.params.id}`,
          headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'Content-Type': 'multipart/form-data'
          }
      }).then( () => {
          store.state.toastMessage = 'Product Deleted!'
          store.state.toastColor = 'success'
          store.dispatch('openToast')
          store.dispatch('getAdminProducts', {page: 1, subcategory_id: route.params.id})

          router.push({name:'admin products'})

          // location.reload()
          
      }).catch((errors) => {
          store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
          store.state.toastColor = 'danger'
          store.dispatch('openToast')
      })
    }

    return {
        //vue
        store,
        router,
        
        //main
        en,

        //computed
        
        //functions
        switchLang,
        editProductVersion,
        deleteProductVersion,

        //icons
        cart
    }
  }
})
</script>

<style scoped>

</style>