<template>
    <ion-col size="6" :class="catPopClass">
        <div @click="goCategory(c)" style="box-shadow: 0px 0px 5px #c2c2c2; border-radius:30px 0px 30px 0px; position: relative; display: flex; align-items: center" class="btn">
            <img style="border-radius: 30px 0px 30px 0px; height: 150px; width: 100%; object-fit: cover; overflow: hidden" :src="category.image">
            <div style="color: white; text-shadow: 0px 0px 10px #000; position: absolute; width:100%; text-align: center">{{en ? category.name_en : category.name_ar}}</div>
        </div>
    </ion-col>
</template>

<script setup>
import { computed, ref, defineProps } from 'vue'
import { IonCol } from '@ionic/vue';
import store from '@/store/index';
import router from '@/router/index';

    const props = defineProps({
        category: Object,
        path: String
    })

    const en = computed(() => store.state.lang == 'en')
    
    const catPopClass = ref()

    if(store.state.intro){
        setTimeout(() => {
            store.state.intro = false
            catPopClass.value = 'popOver'
        }, 1000)
    } else {
            catPopClass.value = 'popOver'
    }


    const goCategory = (c) => {
        router.push(props.path)
    }

</script>

<style scoped>

    .popOver {
        -webkit-animation: bummer 0.5s;
        animation: bummer 0.5s;
        -webkit-transform: scale(0,0); 
        transform: scale(0,0);
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                            close after the animation completes */
    }

    @-webkit-keyframes bummer {
      100% {
          -webkit-transform: scale(1,1); 
      }
    }
    
    @keyframes bummer {
        100% {
            transform: scale(1,1); 
        }
    }

    .btn:active {
        transform: scale(0.9)
    }

</style>